.menu-toggle, .menu-close {
    position: fixed;
    left: 1rem;
    top: 1rem;
    font-size: 4rem;
    line-height: 4.4rem;
    display: inline-block;
    padding: 0.5rem 1.2rem;
    z-index: 11;
    text-shadow: 0 -1px 2px rgba(255,255,255,0.7), 0 1px 2px rgba(255,255,255,0.7);
    border: 1px solid rgba(255,255,255,0.9);
    background: rgba(255,255,255,0.7);
    border-radius: 7px;
    transition: 0.2s;
    cursor: pointer;

    .fa-bars {
        color: rgba(0,0,0,0.8);
    }

    i { margin-top: 2px; transition: 0.2s; }

    &:hover {
        margin-top: 2px;
        i { margin-top: 3px; }
    }
}

.header {
    position: fixed;
    width: 320px;
    padding: 15px;
    top: 0;
    bottom: 0;
    left: -320px;
    z-index: 10;
    background: #eee;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 60%, rgba(249,249,249,1) 100%);
    text-align: center;
    font-size: 1.6rem;
    box-shadow: 0 3px rgba(0,0,0,0.25);
    border-right: 1px solid #110208;
    color: #322;
    transition: 0.2s;
    overflow: auto;

    &.open {
        left: 0;
    }

    .avatar {
        box-sizing: border-box;
        border-radius: 100%;
        margin: 2rem;
        padding: 0;

        img {
            box-sizing: border-box;
            border-radius: 100%;
            border: 3px solid #ff007e;
            padding: 0;
            margin: 0;
            width: 200px;
            height: 200px;
        }
    }

    .progress { height: 3px; }
    .progress-bar {
        height: 3px;
    }

    .projects {
        a {
            width: 32%;
            height: 100%;
            padding: 15px;
            transition: 0.2s;
            display: inline-block;
            border: 1px solid rgba(255,255,255,0);
        }

        img {
            height: 50px;
            filter: invert(0.1) grayscale(0.9) drop-shadow(0 1px 1px rgba(0,0,0,0.1));
            transition: 0.4s;

            &:hover {
                filter: invert(0) grayscale(0) drop-shadow(0 1px 3px rgba(0,0,0,0.25));
            }
        }

        .header-link.active img {
            filter: grayscale(0) drop-shadow(0 1px 5px rgba(0,0,0,0.4));
            transition: 0.4s;
        }

        a:hover {
            background: rgba(255,230,255,0.20);
            border: 1px solid rgba(255,255,255,0);
        }
    }

    .external {
        font-size: 2rem;
        a { display: inline-block; width: 6rem; height: 6rem; line-height: 6rem; font-size: 3.2rem; }
        a:hover { text-decoration: none; }
    }

    .cv-download {
        text-decoration: none;
        display: inline-block;
        padding: 5px 1.4rem;
        border-radius: 30px;
        border: 1px dashed pink;
        transition: 0.4s;

        i { margin-right: 1rem; font-size: 2rem; }

        &:hover {
            color: red;
            border: 1px solid pink;
        }
    }

    .copyright {
        margin-top: 10vh;
    }
}

.header-link.active {
    padding: 10px 20px;
    background: rgba(255,250,250,0.1);
    border: 1px solid rgba(255,255,255,0.5);
}

.header-divider {
    height: 100%;
    display: inline-block;
    background: rgba(255,255,255,0.1);
    width: 1px;
}
