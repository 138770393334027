.App {
}

main {
    margin-left: 0;

    @media(min-width: 1600px) {

        margin-left: 318px;
    }

    .headliner {
        margin-bottom: 4rem;
    }
}

a, button {
    cursor: pointer;
}
