@import 'base';
@import '~bootstrap/scss/bootstrap.scss';

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #222;
  color: #fff;
  font-size: 10px;
  font-family: Mulish, Raleway, Arial, sans-serif;
}

h1, h2, h3, h4, h5 {
  margin: 15px 0;

  .subtitle {
      display: block;
      font-size: 60%;
      text-indent: 15px;
  }
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: 3.8rem;
}

h3 {
  font-size: 2.6rem;
}

h4 {
  font-size: 2.2rem;
}

h5 {
  font-size: 2rem;
}

p, label, input, select, ul, ol {
  font-size: 1.7rem;
}

p { margin: 20px 0; }

.list {
  list-style: disc;
}

input {
  padding: 5px;
  color: $blue;
  border-radius: 3px;
  border: 2px solid rgba(0,0,0,0.1);
}
select { border: 2px solid rgba(0,0,0,0.1); }

img { max-width: 100%; }

.visible {
  display: block;
}

.hide {
  display: none;
}

.progress {
  background: #fcddf5;
}

a { color: $magenta; transition: 0.2s; }
//a:hover { color: #ffee00; }
button, .btn { font-size: 1.6rem; }

hr { border-color: rgba(0,0,0,0.1); }

*,
*:before,
*:after {
  box-sizing: inherit;
}

/**** ------------------------ ****/


