// @import 'base';

.page-layout {
  background: #222;
  //padding-left: 16rem;
}

@media(max-width: 1600px) {
  .page-layout {
    padding-left: 0;
  }
}

.page { padding: 0; }

.page-content {
  h2 { border-bottom: 2px solid #ffcc00; padding: 15px; }

  .form-group {
    max-width: 900px;
    margin: auto;
  }
}

.content {
  padding: 1.5rem;
}

footer {
  border-top: 3px solid rgba(0,0,0, 0.9);
  padding: 1rem 2rem;
  font-size: 4rem;

  text-align: center;
  background: #4b3d50;
  transition: color 0.2s;

  a {
    color: #fff;
    line-height: 2rem;
    &:hover { color: #fff; text-decoration: none; }
  }
}
