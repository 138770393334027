/*!
 * Strapon - Very Professional
 */

$about-color: #5c5d76;
$peerfit-color: #e61761;
$charterhook-color: #01a1c1;
$grooveshark-color: #f47f22;
$sharpspring-color: #84c340;
$bugjar-color: #fac340; // #89d000;
$uf-blue-color: #2e3860; // #0021A5;
$scout-color: #f52555;

.fancy {
  font-family: "Oleo Script", "Georgia", serif;
}

.hide {
  display: none;
}

nav {
  //background: rgba(79, 77, 97, 0.95);

  .nav-item {
    line-height: 3.75rem;
  }

  a.symbol { font-size: 6rem; }

  a.symbol:before {
    margin: 0;
  }
}

.about-me {
  padding: 2rem 3rem;

  a:hover { color: #fff; text-decoration: none; }
}

p.intro { font-size: 2rem; }

.page-logo-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  align-content: center;

  .badges {
    position: absolute;
    bottom: 5rem;
    left: 0;
    right: 0;
    text-align: left;
  }

  .scroll-indicator {
    top: -1.2rem;
    text-align: right;
    font-size: 3rem;
    right: 1rem;
  }
}

.page-logo {
  height: auto;
  max-height: 360px;
  max-width: 80%;
  min-width: 220px;
  margin: 10vh auto;
  margin-top: -4vh;
  //filter: grayscale(0) drop-shadow(0 1px 0 rgba(0,0,0,0.5));

  animation-name: logo-drops;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}

.page.projects {
    text-shadow: 0 1px 1px rgba(0,0,0,0.1);
    padding: 0 2rem 1rem 2rem;
    min-height: 100vh;
    border-left: 2px solid rgba(0,0,0,0.2);
    transition: background-color 0.25s;

    .project-logo {
        max-width: 90%;
        width: 300px;
        margin-bottom: 2rem;
    }

    .badge {
        font-size: 1.4rem;
        background-color: rgba(0,0,0,0.2);
        margin-right: 7px;
        margin-bottom: 5px;
        padding: 5px 10px;
        border-radius: 10px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.1);
        border: 1px solid rgba(255,255,255,0.4);
    }

    p { font-size: 1.6rem; }
    a { text-decoration: underline; }
}

.home-content {
  background-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(247,245,253,1) 40%, rgba(251,246,255,1) 100%);
  //background-image: linear-gradient(90deg, rgba(61,4,56,1) 0%, rgba(5,2,101,1) 40%, rgba(42,1,79,1) 60%, rgba(64,60,70,1) 100%);
}

.benefithub {
  background: #06e;
  //background-image: linear-gradient(90deg, rgba(0,80,187,1) 0%, rgba(37,94,235,1) 35%, rgba(3,53,214,1) 100%);
  background-image: linear-gradient(90deg, rgba(0,80,187,0.5) 0%, rgba(37,94,235,0.5) 35%, rgba(3,53,214,0.5) 100%);

  a { color: #fff; text-decoration: underline; }
}

.peerfit {
  background: $peerfit-color;
  background-image: linear-gradient(90deg, rgba(217,6,82,0.5) 0%, rgba(230,23,97,0.5) 39%, rgba(230,23,97,0.5) 100%);

  a { color: #fff; }
}

.charterhook {
  background-color: $charterhook-color;
  background-image: linear-gradient(90deg, rgba(1,144,173,0.5) 0%, rgba(1,161,193,0.5) 39%, rgba(1,161,193,0.5) 100%);
  color: #fff;

  a { color: #fff; }
}

.sharpspring {
  background-color: $sharpspring-color;
  background-image: linear-gradient(90deg, rgba(112,181,29,0.5) 0%, rgba(132,195,64,0.5) 39%, rgba(132,195,64,0.5) 100%);
  color: #fff;

  a { color: #fff; }
}

.bugjar {
  background-color: $bugjar-color;
  background: linear-gradient(90deg, rgba(185,214,16,0.9) 0%, rgba(250,195,64,0.9) 40%, rgba(250,195,64,0.9) 100%);
  color: #fff;

  a { color: #fff; }
}

.grooveshark {
  background-color: $grooveshark-color;
  background-image: linear-gradient(90deg, rgba(255,121,56,1) 0%, rgba(244,127,34,0.5) 39%, rgba(244,127,34,0.5) 100%);
  color: #fff;

  a { color: #fff; }
}

.scout {
  background-color: $scout-color;
  background-image: linear-gradient(90deg, rgba(162,35,76,0.2) 0%, rgba(178,35,78,0.2) 2%, rgba(162,35,76,0.2) 2%, rgba(162,35,76,0.2) 25%, rgba(245,37,85,0.2) 25%, rgba(246,45,92,0.2) 29%, rgba(252,97,141,0.2) 29%, rgba(252,59,112,0.2) 47%, rgba(252,97,141,0.2) 47%, rgba(252,97,141,0.2) 77%, rgba(255,172,22,0.2) 77%, rgba(255,194,51,0.2) 88%);
}

.reitz {
  background-color: $uf-blue-color;
  background-image: linear-gradient(90deg, rgba(19,24,41,1) 0%, rgba(46,56,96,0.5) 39%, rgba(46,56,96,0.5) 100%);
}

.fillwhite {
  fill: white;
  color: #fff;
}

.border-button {
  font-size: 2rem;
  padding: 1rem 3rem;
  border: 2px solid #fff;
  display: inline-block;
  border-radius: 3px;
  transition: 0.2s;
  text-decoration: none !important;
  background: rgba(255,255,255,0.2);

  &:hover {
    background: rgba(255,255,255,0.1);
  }
}

@keyframes logo-drops {
  0%   {filter: drop-shadow(0 1px 0 rgba(0,0,0,0.25));}
  25%  {filter: drop-shadow(-5px 6px 0 rgba(0,0,0,0.25));}
  50%  {filter: drop-shadow(3px 4px 0 rgba(0,0,0,0.25));}
  100% {filter: drop-shadow(0 1px 0 rgba(0,0,0,0.25));}
}
