.gallery {
    display: flex;
    gap: 1rem;
    max-width: 100%;
    flex-wrap: wrap;

    img {
        border: 3px solid #fff;
        box-shadow: 0 2px 3px rgba(0,0,0,0.2);
    }

    .showcase-image {
        border: 5px solid rgba(255,255,255,0.75);
        margin-bottom: 1rem;

        &.transparent-bg {
            border: none;
            box-shadow: none;
        }
    }
}
