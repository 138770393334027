.home-content {
  background-color: #fffefe;
  background-size: cover;
  //color: #dcf2ff;
  color: #322;

  padding-top: 50px;
  border-left: 3px solid #f95584;

  h1, h2, h3 { color: #102; }
  h1 { font-size: 6rem; }
  h2 { font-size: 4.4rem; }

  font-size: 1.8rem;

  .btn-xl {
    margin: 0 1rem 1rem 0;
    font-size: 2rem;
    font-weight: 600;
    border-radius: 30px;
    padding: 1rem 3rem;

    &:hover {
      color: #fff;
    }
  }
}
