.page-about {
    position: relative;
    padding: 15px;
    min-height: 100vh;

    .hello {
        border: none;
        background: none;
        text-align: left;
    }

    h1 { position: relative; display: inline-block; margin-bottom: 0; text-align: left; }
    .special-character { position: relative; z-index: 1; }
    .accent {
        position: absolute;
        font-size: 3rem;
        top: -5px;
        right: -5px;
        color: #960;
        transition: 0.2s;
        opacity: 0.2;
        width: 50px;
        height: 50px;
        z-index: -1;
        transition-timing-function: ease-in-out;

        svg { max-width: 100%; max-height: 100%; }

        &.horns { fill: red; left: -6px; }
    }

    .hat { transform: rotate(-9deg); top: -5px; }
    .horns { transform: rotate(-12deg); }
    .bacon { left: 10px; transform: rotate(-90deg); top: -10px; color: red }
    .anchor { font-size: 16px; color: #164D8F; left: 30px; transform: rotate(-12deg); transition: 4s; }

    h1:hover {
        text-shadow: -3px 1px 1px rgba(255,200,200,0.5);
        .hat { transform: rotate(-20deg); opacity: 1; top: -12px; }
        .horns { scale: 1.1; opacity: 1; }
        .bacon { animation: horizontal-shaking 500ms infinite; opacity: 1; }
        .anchor { top: 79px; transition: 4s; transform: rotate(-3deg); opacity: 0.9; }
    }

    h1, h2 {
        text-shadow: -3px 1px 1px rgba(255,200,200,0.9);
    }

    section {
        min-height: 30vh;
    }

    .headliner {
        position: relative;
        min-height: 90vh;
        display: flex;
        align-content: center;
        font-size: 2rem;
    }

    .progress {
        max-width: 400px;
        box-shadow: 0 0 7px rgba(255, 255, 255, 0.5);
    }

    .intro {
        border-left: 3px solid rgb(249, 85, 132);
        padding-left: 20px;
        margin: 0.5rem 0 2rem 0;

        strong { font-size: 4rem; font-weight: normal; }
    }

    li { font-size: 1.8rem; }

    .scroll-indicator {
        position: absolute;
        text-align: right;
        right: 1rem;
        bottom: 1rem;
        font-size: 3rem;
    }

    .featured-project {
        min-height: 16rem;
    }

    a {
        text-decoration: none;
        display: inline-block;

        i {
            position: relative;
            transition: 0.2s;
        }

        &:hover {
            i:last-child { margin-left: 5px; }
        }
    }

    .initials {
        position: relative;
        margin-top: 2rem;
        filter: drop-shadow(0 1px 1px rgba(0,0,30,0.5));
        animation-name: initials-drops;
        animation-duration: 15s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: 3s;
    }

}

@keyframes initials-drops {
    0%   {filter: drop-shadow(0 1px 1px rgba(0,0,30,0.5)); top: 0; left: 0;}
    20%  {filter: drop-shadow(-1px 3px 4px rgba(0,0,30,0.4)); top: -20px;}
    40%  {filter: drop-shadow(1px 2px 4px rgba(0,0,30,0.4)); top: 0;}
    60% {filter: drop-shadow(0 1px 2px rgba(0,0,30,0.5)); top: 0; left: 0;}
    100% {filter: drop-shadow(0 1px 1px rgba(0,0,30,0.5)); top: 0; left: 0;}
}

@keyframes horizontal-shaking {
    0% { transform: rotate(-90deg) translateX(0) }
    25% { transform: rotate(-90deg) translateX(5px) }
    50% { transform: rotate(-90deg) translateX(-5px) }
    75% { transform: rotate(-90deg) translateX(5px) }
    100% { transform: rotate(-90deg) translateX(0) }
}
